<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <van-grid :column-num="4">
      <van-grid-item
        class="ic"
        icon="bulb-o"
        :text="t('remote_controller')"
        @click="$router.push({ path: '/remote_controller' })"
      />
      <!-- <van-grid-item
        class="ic"
        icon="smile-o"
        :text="t('expressions')"
        @click="$router.push({ path: '/expressions' })"
      /> -->
      <van-grid-item
        class="ic"
        icon="setting-o"
        :text="t('settings')"
        @click="$router.push({ path: '/settings' })"
      />
    </van-grid>
  </div>
</template>

<style scoped>
.ic {
  cursor: pointer;
}
</style>

<script>
// @ is an alias to /src
import { t } from "@/utils/translate";
import { Grid, GridItem } from "vant";
import { connect, disconnect, writeData } from "../utils/bluetoothhelper";
import * as ble from "../utils/bluetoothhelper";

export default {
  name: "Home",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  methods: {
    t(tag) {
      return t(tag);
    },
  },
  async mounted() {
    this.$store.commit("navTitle", t("home"));
    // let store = this.$store;
    // window["store"] = store;
    window["bleconnect"] = connect;
    window["bledisconnect"] = disconnect;
    window["bleWriteData"] = writeData;
    window["ble"] = ble;
  },
};
</script>
